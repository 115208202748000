<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, Stat, VclList },
  data() {
    return {
      statData: null,

      membership: {
        status: "inactive",
        expiration: null,
      },

      buy: {
        modal: false,
        loading: false,
        plans: null,
        plan: null,
        balance: "0.00",
      },

      table: {
        heade: [
          "Usuário",
          "Nome",
          "Pedido",
          "Data",
          "Valor",
          "Nível",
          "%",
          "Bonus",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getMembership() {
      api.get("membership").then((response) => {
        if (response.data.status == "success") {
          if (
            response.data.membership.date &&
            response.data.membership.date.end
          ) {
            this.statData = [
              {
                icon: "monthly",
                title: "Status",
                value: response.data.membership.status,
              },
              {
                icon: "expiration",
                title: "Expira em",
                value: response.data.membership.date.end,
              },
            ];
          } else {
            this.buy.modal = true;

            this.statData = [
              {
                icon: "monthly",
                title: "Status",
                value: response.data.membership.status,
              },
            ];
          }
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          icon: "monthly",
          title: "Status",
          value: this.membership.status,
        },
      ];
    },
    getPlans() {
      api.get("membership/plans").then((response) => {
        if (response.data.status == "success") {
          this.buy.plans = response.data.list;
        }
      });
    },
    getBalance() {
      api.get("wallet/balance/network").then((response) => {
        this.buy.balance = response.data.balance;
      });
    },
    buyMembership() {
      this.buy.loading = true;

      api
        .post("membership/plans", {
          plan: this.buy.plan.id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.buy.loading = false;
            this.buy.modal = false;
            this.getMembership();
            this.getOrders();
          }
        });
    },
    getOrders() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("membership/orders")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getStatData();
    this.getMembership();
    this.getOrders();
    this.getPlans();
    this.getBalance();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Minha Assinatura") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-3 col-lg-3 col-xl-3"
      >
        <Stat
          :type="stat.type"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <b-modal
      v-model="buy.modal"
      :title="t('Minha Assinatura')"
      centered
      size="lg"
    >
      <div
        class="d-flex justify-content-between align-items-center bg-soft-secondary rounded mb-3 p-3"
      >
        <h5 class="m-0">SALDO DISPONÍVEL</h5>
        <h5 class="m-0">{{ buy.balance | currency }}</h5>
        <div>
          <router-link
            :to="'/wallet/extract'"
            class="btn btn-default btn-xs px-2 py-0"
          >
            DEPOSITAR
          </router-link>
        </div>
      </div>
      <div v-if="!buy.plan" class="row">
        <div
          class="col-md-6 text-center"
          v-for="(plan, index) in buy.plans"
          :key="index"
        >
          <div class="bg-soft-secondary rounded p-3 py-4">
            <p class="font-size-11 m-0">PLANO</p>
            <h4>{{ plan.name }}</h4>
            <h5 class="bg-white rounded p-2 mt-3 mb-2">
              {{ plan.monthly }} meses
            </h5>
            <h4 class="mb-3">{{ plan.value | currency }}</h4>
            <button class="btn btn-success btn-lg" v-on:click="buy.plan = plan">
              ESCOLHER
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mb-2 text-center">
          <button
            class="btn btn-link text-default"
            style="text-decoration: none"
            v-on:click="buy.plan = null"
          >
            ALTERAR
          </button>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <div class="bg-soft-secondary rounded p-3 py-4">
              <p class="font-size-11 m-0">PLANO</p>
              <h4>{{ buy.plan.name }}</h4>
              <h5 class="bg-white rounded p-2 mt-3 mb-2">
                {{ buy.plan.monthly }} meses
              </h5>
              <h4 class="mb-3">{{ buy.plan.value | currency }}</h4>
              <button
                class="btn btn-success btn-lg"
                v-on:click="buyMembership()"
                :disabled="buy.balance == '0.00'"
              >
                CONFIRMAR COMPRA
                <b-spinner
                  v-if="buy.loading"
                  small
                  class="ml-2 align-middle"
                  variant="white"
                  role="status"
                ></b-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Início</th>
                    <th>Final</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td class="notranslate">{{ td.id }}</td>
                    <td class="notranslate">{{ td.date.start }}</td>
                    <td class="notranslate">{{ td.date.end }}</td>
                    <td class="notranslate">
                      <span
                        v-if="td.status === 'pending'"
                        class="badge badge-soft-warning font-size-12"
                        >Aguardando pagamento</span
                      >
                      <span
                        v-else-if="td.status === 'approved'"
                        class="badge badge-soft-success font-size-12"
                        >Pago</span
                      >
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
